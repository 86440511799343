import React, {createContext} from "react";
import {useSubscription} from "@apollo/client";
import {OPTIMISATION_BATCH_SUBSCRIPTION} from "../pages/batchOptimisation/BatchOptimisationGQL";

import {ApolloError} from "@apollo/client";
export const SubscriptionContext = createContext({
  batchOptimisation: {
    data: null,
    error: undefined as ApolloError | undefined,
    loading: false
  }
});

export const SubscriptionProvider = ({ children }) => {
    const batchOptimisation = useSubscription(OPTIMISATION_BATCH_SUBSCRIPTION, {fetchPolicy: "no-cache"});

    const contextObject = {
        batchOptimisation: {
            data: batchOptimisation.data,
            error: batchOptimisation.error,
            loading: batchOptimisation.loading
        },
    }
    return (
        <SubscriptionContext.Provider value={contextObject}>
            {children}
        </SubscriptionContext.Provider>
    );
}
