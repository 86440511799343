import React, {FC, PropsWithChildren, useCallback, useContext, useEffect} from "react";
import LocalizedStrings from 'react-localization';
import {enStrings} from './strings/enStrings';
import {jaStrings} from './strings/jaStrings';
import {SettingsContext} from "../providers/SettingsProvider";
import {useQuery} from "@apollo/client";
import {contractTypeIdMapping, GET_USER_DATA_QUERY} from "components/pages/users/UserGql";
import useLocalStorage from "../utils/LocalStorage";
import {AuthContext} from "./AuthProvider";
import {Station} from "../utils/StationPicker";
import {useHolidays} from "../calendar/HolidayGql";
import {GET_BREAK_RULES_QUERY, GET_CONTRACT_TYPES_QUERY} from "../pages/settings/SettingsGql";
import {FilteredLocation} from "../utils/LocationFilter";
import {useManagementHierarchy, validateFilteredLocation} from "../utils/ManagementHierarchy";
import {BreakRulesOptionsInterface} from "../pages/rostering/common/gql/GanttGqlInterfaces";
import {
    isEmployeeHighschooler,
    isForeigner,
    isForeignStudent
} from "../pages/rostering/common/StaffListProvider";

const strings = new LocalizedStrings({
    'en-GB': enStrings,
    'ja': jaStrings,
});

const managerStations = ["店長" , "Manager"];

export enum GanttViews {
    taskAndStaff = "taskAndStaff",
    task = "tasks",
    staff = "staff"
}

export const GeneralContext = React.createContext({
    userData: {} as any,
    strings: strings,
    language: "",
    locationIds: [] as number[],
    managementHierarchy: [] as any[] | undefined,
    refetchManagementHierarchy: () => {},
    getSelectedLocation: () => ({}) as any | undefined,
    setSelectedLocation: (locationId: number) => {},
    getSelectedManagerId: () => ({}) as string | undefined,
    getSelectedLocations: () => ({}) as any[],
    getSeniorManagerName: (locationId: string, language: string) => ({}) as any,
    selectedGanttView: "",
    setSelectedGanttView: (view) => {},
    filteredLocation: undefined as FilteredLocation | undefined,
    setFilteredLocation: (filterdLocation: FilteredLocation | undefined) => {},
    selectedStation: undefined as Station | undefined,
    setSelectedStation: (station: Station | undefined) => {},
    availabilityTabSelected: 0,
    setAvailabilityTabSelected: (tab: number) => {},
    availabilityReviewTabSelected: 0,
    setAvailabilityReviewTabSelected: (tab: number) => {},
    fixedPositionTabSelected: 0,
    setFixedPositionTabSelected: (tab: number) => {},
    userRole: "",
    isHoliday: (date: Date) => ({}),
    isFullTimeStaff: () => ({} as boolean | undefined),
    isPartTimeStaff: () => ({} as boolean | undefined),
    managerStations: managerStations,
    contractTypes: undefined as any[] | undefined,
    breakRulesOptions: undefined as BreakRulesOptionsInterface | undefined,
    platesToBaseHoursRatio: 0,
    getMaxShiftLength: (employeeContract?: any) => ({}) as number,
    getMaxShiftLengthForForeignStudent: (employee: any, date: Date, schoolHolidays?: any[]) => ({}) as number | undefined,
    calculateMaxHoursPerWeekForForeignStudent: (employee: any, firstDayOfWeek: Date, schoolHolidays?: any[]) => ({}) as number,
    convertToNetHours: (netHours: number) => ({}) as number,
    convertToGrossHours: (netHours: number) => ({}) as number,
    getDayNumber: (date: Date) => ({}) as number,
    getMergedContract: (employee: any) => ({}) as any | undefined
});

// Context logic

const userdataContext = ({ data }, locationIds) => {
    if (data && data.myEmployee) {
        locationIds = data.myEmployee.restaurants.map(x => x.id);
    }
    return locationIds;
};

const langContext = (settings) => {
    if (!!settings.language) {
        localStorage.setItem("languageContextObject", settings.language);
    }

    strings.setLanguage(settings.language);
};

export const GeneralProvider: FC<PropsWithChildren> = ({children}) => {
    const auth = useContext(AuthContext);
    const holidays = useHolidays();
    const {managementHierarchy, getSelectedLocation, getSelectedLocations, getSelectedManagerId, refetchManagementHierarchy, getSeniorManagerName} = useManagementHierarchy();
    const { data } = useQuery(GET_USER_DATA_QUERY, {skip: !auth.isAuthenticated()});
    const { data: contractData } = useQuery(GET_CONTRACT_TYPES_QUERY, {skip: !auth.isAuthenticated()});
    const contractTypes = contractData?.contractTypeAndOvertimeRules?.contractTypes;
    const settings = useContext(SettingsContext);
    let locationIds: number[] = [];
    const [filteredLocation, setFilteredLocation] = useLocalStorage<FilteredLocation | undefined>("filteredLocation", undefined);
    const [selectedStation, setSelectedStation] = useLocalStorage<Station | undefined>("selectedStation", undefined);
    const [ganttView, setGanttView] = useLocalStorage<string>("ganttView", GanttViews.staff);
    const [availabilityTabSelected, setAvailabilityTabSelected] = useLocalStorage<number>("availabilityTabSelected", 0);
    const [availabilityReviewTabSelected, setAvailabilityReviewTabSelected] = useLocalStorage<number>("availabilityReviewTabSelected", 0);
    const [fixedPositionTabSelected, setFixedPositionTabSelected] = useLocalStorage<number>("fixedPositionTabSelected", 0);

    const {
        data: breakRulesData
    } = useQuery(GET_BREAK_RULES_QUERY, {
        skip: !auth.isAuthenticated() || process.env.REACT_APP_BUILD === 'staff' || auth.getUserRole() === 'Staff'
    });

    const breakRulesOptions = breakRulesData?.workHoursRestrictions;
    const breakRules = breakRulesOptions?.breakRules;
    const platesToBaseHoursRatio = breakRulesOptions?.organisationSettings.platesToBaseHoursRatio ?? 0;

    useEffect(() => {
        if (managementHierarchy) {
            const result = validateFilteredLocation(managementHierarchy, filteredLocation);
            if (!result.valid) {
                setFilteredLocation(result.validated);
            }
        }
    }, [
        managementHierarchy,
        filteredLocation,
        setFilteredLocation
    ]);

    const getUserRole = () => {
        let role: string = auth.getUserRole() as string;

        if (data?.myEmployee) {
            role = data.myEmployee.role;
        }

        return role;
    };

    const isHoliday = (date: Date) : boolean => {
        if (date.getDay() === 0) {
            return false; // Sunday is not a holiday in KS
        }
        return holidays.find(holiday => holiday.valueOf() === date.valueOf()) !== undefined;
    };

    const getDayNumber = (date: Date) : number  => {
        return isHoliday(date) ? 7 : date.getDay();
    }

    const isFullTimeStaff = () : boolean | undefined => {
        let isFullTimeStaff: boolean | undefined;
        if (data?.myEmployee) {
            isFullTimeStaff = data.myEmployee.contract.contractTypeId === contractTypeIdMapping.fullTimeId && data.myEmployee.role === "Staff"
        }
        return isFullTimeStaff;
    };

    const isPartTimeStaff = () : boolean | undefined => {
        let isPartTimeStaff: boolean | undefined;
        if (data?.myEmployee) {
            isPartTimeStaff = data.myEmployee.contract.contractTypeId !== contractTypeIdMapping.fullTimeId && data.myEmployee.role === "Staff"
        }
        return isPartTimeStaff;
    };

    const setSelectedLocation = (locationId: number) => {
        managementHierarchy?.some((rm) =>
            rm.areaManagers.some((am) =>
                am.supervisors.some((sv) =>
                    sv.stores.some((location) => {
                        if (location.id === locationId) {
                            setFilteredLocation({
                                regionId: rm.identityId,
                                areaId: am.identityId,
                                supervisorId: sv.identityId,
                                locationId: location.id,
                                locationNumber: location.locationNumber,
                                name: location.name
                            });
                            return true;
                        }
                        return false;
                    })
                )
            )
        )
    }

    const getMaxShiftLength = (employeeContract?: any) => {
        if (breakRules && employeeContract?.maxHoursPerDay > 0) {
            const maxHoursPerDay = employeeContract.maxHoursPerDay * 3600;
            for (let hours = maxHoursPerDay + 900; hours < 24 * 3600; hours += 900) {
                const breakRule = breakRules?.find((rule) => {
                    return rule.minHours <= hours && rule.maxHours > hours;
                });
                if (breakRule) {
                    if (hours - breakRule.breakLength === maxHoursPerDay) {
                        return hours;
                    }
                }
            }
        }
        return 24 * 3600;
    }

    const convertToNetHours = useCallback((grossHours: number) => {
        if (breakRules) {
            const breakRule = breakRules?.find((rule) => {
                return rule.minHours <= grossHours && rule.maxHours > grossHours;
            });
            if (breakRule) {
                return grossHours - breakRule.breakLength;
            }
        }
        return grossHours;
    }, [breakRules]);

    const convertToGrossHours = useCallback((netHours: number) => {
        if (breakRules) {
            for (let hours = netHours + 900; hours < 24 * 3600; hours += 900) {
                const breakRule = breakRules?.find((rule) => {
                    return rule.minHours <= hours && rule.maxHours > hours;
                });
                if (breakRule) {
                    if (hours - breakRule.breakLength === netHours) {
                        return hours;
                    }
                }
            }
        }
        return netHours;
    }, [breakRules]);

    const getMaxShiftLengthForForeignStudent = useCallback((employee: any, date: Date, schoolHolidays?: any[]) => {
        if (!isForeignStudent(employee)) return undefined;

        const holidays = schoolHolidays?.filter(h => h.employeeId === employee.identityId);
        const holidayRule = contractTypes?.find(contract => contract.id === contractTypeIdMapping.foreignStudentHolidayId);
        if (!holidays || !holidayRule) return undefined;

        if (holidays.find(h => h.start.getTime() <= date.getTime() && h.end.getTime() >= date.getTime())) {
            return convertToGrossHours(holidayRule.maxHoursPerDay * 3600);
        }
        return undefined;
    }, [convertToGrossHours, contractTypes]);

    const calculateMaxHoursPerWeekForForeignStudent = useCallback((employee: any, firstDayOfWeek: Date, schoolHolidays?: any[]) => {
        if (!isForeignStudent(employee)) return undefined;

        const holidays = schoolHolidays?.filter(h => h.employeeId === employee.identityId);
        const holidayRule = contractTypes?.find(contract => contract.id === contractTypeIdMapping.foreignStudentHolidayId);
        if (!holidays || !holidayRule) return employee.maxHoursPerWeek;

        let countOfHolidays = 0;
        for (let day = 0; day < 7; day++) {
            const date = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + day);
            date.setHours(0, 0, 0, 0);
            if (holidays.find(h => h.start.getTime() <= date.getTime() && h.end.getTime() >= date.getTime())) {
                countOfHolidays++;
            }
        }
        return (holidayRule.maxHoursPerWeek * 3600 * countOfHolidays + employee.maxHoursPerWeek * (7 - countOfHolidays)) / 7;
    }, [contractTypes]);

    const getMergedContract = useCallback((employee: any) => {
        if (isForeigner(employee) && isEmployeeHighschooler(employee)) {
            return contractTypes?.find(type => type.id === contractTypeIdMapping.highSchoolStudentId);
        }
        const contractTypeId = employee?.contract?.contractTypeId ?? employee?.contractTypeId;
        return contractTypes?.find(type => type.id === contractTypeId);
    }, [contractTypes]);

    locationIds = userdataContext({ data }, locationIds);
    langContext(settings);

    const generalContextObj = {
        userData: data,
        strings: strings,
        language: settings.language,
        locationIds: locationIds,
        managementHierarchy: managementHierarchy,
        getSeniorManagerName: getSeniorManagerName,
        refetchManagementHierarchy: refetchManagementHierarchy,
        getSelectedLocation: () => getSelectedLocation(filteredLocation),
        setSelectedLocation: setSelectedLocation,
        getSelectedManagerId: () => getSelectedManagerId(filteredLocation) ?? data?.myEmployee?.identityId,
        getSelectedLocations: () => getSelectedLocations(filteredLocation),
        filteredLocation: filteredLocation,
        setFilteredLocation: setFilteredLocation,
        selectedStation: selectedStation,
        setSelectedStation:setSelectedStation,
        selectedGanttView: ganttView,
        setSelectedGanttView: setGanttView,
        availabilityTabSelected: availabilityTabSelected,
        setAvailabilityTabSelected: setAvailabilityTabSelected,
        availabilityReviewTabSelected: availabilityReviewTabSelected,
        setAvailabilityReviewTabSelected: setAvailabilityReviewTabSelected,
        fixedPositionTabSelected: fixedPositionTabSelected,
        setFixedPositionTabSelected: setFixedPositionTabSelected,
        userRole: getUserRole(),
        isHoliday: isHoliday,
        getDayNumber: getDayNumber,
        isFullTimeStaff: isFullTimeStaff,
        isPartTimeStaff: isPartTimeStaff,
        managerStations: managerStations,
        contractTypes: contractTypes,
        breakRulesOptions: breakRulesOptions,
        platesToBaseHoursRatio: platesToBaseHoursRatio,
        getMaxShiftLength: getMaxShiftLength,
        getMaxShiftLengthForForeignStudent: getMaxShiftLengthForForeignStudent,
        calculateMaxHoursPerWeekForForeignStudent: calculateMaxHoursPerWeekForForeignStudent,
        convertToNetHours: convertToNetHours,
        convertToGrossHours: convertToGrossHours,
        getMergedContract: getMergedContract
    };

    return <GeneralContext.Provider value={generalContextObj}>{children}</GeneralContext.Provider>;
};