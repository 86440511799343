import React, {createContext, useState} from "react";
import {BatchData} from "../pages/batchOptimisation/BatchOptimisation";

export const BatchJobStatusContext = createContext({
  data: undefined as BatchData | undefined,
  setData: undefined as any
});

export const BatchJobStatusProvider = ({ children }) => {
  const [batchJobData, setBatchJobData] = useState<BatchData>();

  const contextObject = {
    data: batchJobData,
    setData: setBatchJobData
  }
  return (
    <BatchJobStatusContext.Provider value={contextObject}>
      {children}
    </BatchJobStatusContext.Provider>
  );
}
