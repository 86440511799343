//BATCH OPTIMISATION PAGE
import {gql} from "@apollo/client";

export const GET_BATCH_OPTIMISATION_DATA_QUERY = gql`
    query GetBatchOptimisationData($managerIdAndDateRange: managerIdAndDateRange!) {
        batchJobStatusForManager(managerIdAndDateRange: $managerIdAndDateRange) {
            locationStatuses {
                locationId
                locationNumber
                name
                statuses {
                    date
                    batchJobId
                    status
                }
            }
        }
    }
`;

export const GET_BATCH_OPTIMISATION_DATA_FOR_LOCATION_QUERY = gql`
    query GetBatchOptimisationDataForLocation($locationIdAndDateRange: batchStatusForLocation!) {
        batchJobStatusForLocation(locationIdAndDateRange: $locationIdAndDateRange) {
            locationId
            locationNumber
            name
            statuses {
                date
                batchJobId
                status
                progress
            }
        }
    }
`;

export const OPTIMISATION_BATCH_SUBSCRIPTION = gql`
    subscription OptimiserBatchSubscription {
        batchJobStateChange {
            id
            batchId
            date
            started
            finished
            changeType
            properties
            progress
        }
    }
`;